html, body, .eldro24, .view-container {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  background: transparent url('/assets/images/body-bg.png');

  option,
  option:disabled,
  optgroup {
    background-color: $body-bg;
  }

  .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
