/*
.filters {
  margin-bottom: 1rem;
  min-height: 68px;
  padding-bottom: 30px;

  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  .form-group {
    margin-bottom: 1px;

    .form-control {
      padding: 0.15rem 0.75rem;
    }
  }

  select.form-control:not([size]):not([multiple]) {
    height: calc(1.5rem + 2px);
  }

  .form-control.large:not([size]):not([multiple]) {
    height: calc(2 * 1.95rem + 2px);
  }

  .btn {
    padding: 8px 20px 9px 20px;

    &.large {
      margin-top: 6px;
      padding: 20px 30px;
    }
  }
}
*/

tr.filters {
  .form-group {
    margin-bottom: initial;
  }

  th.filter {
    padding: 0;
  }
}
