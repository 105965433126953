@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/roboto/Roboto-Light.eot");
  src: local("Roboto Light"), local("Roboto-Light"), url("./assets/fonts/roboto/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/roboto/Roboto-Light.woff2") format("woff2"), url("./assets/fonts/roboto/Roboto-Light.woff") format("woff"), url("./assets/fonts/roboto/Roboto-Light.ttf") format("truetype"), url("./assets/fonts/roboto/Roboto-Light.svg#Roboto") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Light";
  src: url("./assets/fonts/roboto/Roboto-Light.eot");
  src: local("Roboto Light"), local("Roboto-Light"), url("./assets/fonts/roboto/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/roboto/Roboto-Light.woff2") format("woff2"), url("./assets/fonts/roboto/Roboto-Light.woff") format("woff"), url("./assets/fonts/roboto/Roboto-Light.ttf") format("truetype"), url("./assets/fonts/roboto/Roboto-Light.svg#Roboto") format("svg");
}

@font-face {
  font-family: "Roboto-Condensed";
  src: url("./assets/fonts/roboto-condensed/Roboto-Condensed-Regular.eot");
  src: local("Roboto-Condensed Regular"), local("Roboto-Condensed-Regular"), url("./assets/fonts/roboto-condensed/Roboto-Condensed-Regular.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/roboto-condensed/Roboto-Condensed-Regular.woff2") format("woff2"), url("./assets/fonts/roboto-condensed/Roboto-Condensed-Regular.woff") format("woff"), url("./assets/fonts/roboto-condensed/Roboto-Condensed-Regular.ttf") format("truetype"), url("./assets/fonts/roboto-condensed/Roboto-Condensed-Regular.svg#Roboto-Condensed") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Condensed-Regular";
  src: url("./assets/fonts/roboto-condensed/Roboto-Condensed-Regular.eot");
  src: local("Roboto-Condensed Regular"), local("Roboto-Condensed-Regular"), url("./assets/fonts/roboto-condensed/Roboto-Condensed-Regular.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/roboto-condensed/Roboto-Condensed-Regular.woff2") format("woff2"), url("./assets/fonts/roboto-condensed/Roboto-Condensed-Regular.woff") format("woff"), url("./assets/fonts/roboto-condensed/Roboto-Condensed-Regular.ttf") format("truetype"), url("./assets/fonts/roboto-condensed/Roboto-Condensed-Regular.svg#Roboto-Condensed") format("svg");
}
