.main-content {
  display: flex;
  flex-direction: row;
  padding: 1px 5px 50px 5px;
  height: calc(100vh - 125px);

  .left-panel {
    width: 320px;
    padding-right: 1px;
    display: flex;
    flex-direction: column;
    //height: 100%;
  }

  .right-panel {
    width: 320px;
    padding-left: 1px;
    display: flex;
    flex-direction: column;

    .element {
      color: #fff;
      border-bottom: 1px solid rgba(157, 157, 157, 0.5);
      font-size: 0.8rem;
      padding: 0.6rem;
    }


    .zgloszenia {
      background-color: rgba(90, 80, 71, 0.7);
    }


    .icon-box {
      margin-left: auto;
      padding-right: 1rem;
      padding-top: 3rem;

      .icon {
        display: block;
        width: 54px;
        height: 54px;
        margin: 10px auto 10px auto;
        background-repeat: no-repeat;

        &.icon-info {
          background-image: url('/assets/images/icon-info.png');
        }
      }
    }

  }

  .main-panel {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .grow {
    flex-grow: 1;
  }

  .header {
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-items: auto;

    span {
      text-align: left;
      flex-grow: 1;
      font-family: "Roboto-Condensed-Regular", sans-serif;
      letter-spacing: 2px;
      display: inline-block;
      text-transform: uppercase;
      padding: 15px 5px;
    }
  }

}
