input {

  &.checkbox {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparentize(theme-color("eldro-gold"), 0.7);
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
    padding: 7px;
    display: inline-block;
    position: relative;
  }
  
  &.checkbox:hover {
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px theme-color("eldro-gold");
  }
  
  &.checkbox:active, &.checkbox:checked:active {
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
  }
  
  &.checkbox:checked:after {
    content: '\2714';
    font-size: 11px;
    position: absolute;
    top: 0px;
    left: 2px;
    color: theme-color("eldro-gold");
  }

  &:focus {
    outline: none;
  }
}
