.vscroll {
  overflow-y: scroll;
}

.hscroll {
  overflow-x: scroll;
}

.fullwidth {
  width: 100%;
}

.today {
  text-align: center;

  span {
    font-size: 16px;
    color: theme-color("eldro-light-gray");
  }

  .divider {
    color: theme-color("eldro-blue");
    display: inline-block;
    margin: 0 5px;
  }
}

h3 {
  color: theme-color("eldro-gold");
  text-transform: uppercase;
  font-family: "Roboto-Light", sans-serif;
  letter-spacing: 2px;
  font-weight: normal;
  font-size: 12px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.inset {
  border: solid 6px theme-color("eldro-inset-dark");
  border-right-color: theme-color("eldro-inset-light2");
  border-bottom-color: theme-color("eldro-inset-light");
  background-image: url('/assets/images/body-bg-dark.png');
}

h5 {
  button.btn.btn-link {
    text-align: left;
    display: block;
    width: 100%;
    color: $eldro-gold;
  }
}
