.list {
  .list-item {
    border-bottom: 1px solid transparentize(theme-color("eldro-light-gray"), 0.5);

  }

  .status-list-item {
    display: flex;
    flex-direction: row;
    height: 50px;

    .name {
      flex-grow: 1;
      height: 100%;
      padding: 5px 10px;
      color: theme-color("eldro-light-gray");
    }

    .status {
      .status-bar {
        display: inline-block;
        width: 10px;
        height: 47px;
        float: left;
        margin: 1px;

        @each $color, $value in $status-colors {
          &.status-bar-#{$color} {
            background-color: transparentize($value, 0.5);
          }
        }
      }
    }
  }
}
