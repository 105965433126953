.form-group {
  @extend .inset;
}

.form-control {
  background-color: transparent;
  border: none;
  color: theme-color("eldro-white");
  letter-spacing: 1px;

  &:focus {
    color: theme-color("eldro-white");
    background-color: rgba(0, 0, 0, 0.1);
    //background-color: transparent;
    box-shadow: 0 0 2px 0.2rem transparentize(theme-color("eldro-gold"), 0.8);
  };

  &::placeholder {
    color: theme-color("eldro-light-gray");
  }

  &.large:not([size]):not([multiple]) {
    height: calc(2 * 1.95rem + 2px);
  }
}

.form-with-labels {
  display: flex;

  label {
    padding: 0.15rem 0rem;
    margin: 0rem;
  }
}

.form-check {
  padding: 5px;

  input {
    display: none;
  }

  label {
    letter-spacing: 1px;
    line-height: 20px;
    vertical-align: top;

    span {
      line-height: 20px;
      vertical-align: middle;
    }

    span.label {
      margin-right: 10px;
    }

    span.check-mark {
      //margin-right: 10px;
      line-height: 20px;
      height: 20px;
      width: 20px;
      border: 1px solid grey;
      display: inline-block;
      position: relative;
      background-image: url('/assets/images/body-bg-dark.png');
    }

    input[type=checkbox]:checked + span.check-mark:before {
      content: '\2713';
      position: absolute;
      top: -2px;
      left: 0;
      color: theme-color("eldro-green");
      font-size: 24px;
    }

    input[type=radio]:checked + span.check-mark:before {
      content: '\2713';
      position: absolute;
      top: -2px;
      left: 0;
      color: theme-color("eldro-green");
      font-size: 24px;
    }
  }
}
