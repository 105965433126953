.navbar {
  height: 125px;
  background: transparent url('/assets/images/navbar-bg.png') repeat-x;
  border-bottom: 1px solid theme-color("eldro-light-gray");
  padding: 0;

  &.navbar-expand-lg .navbar-nav .nav-link {
    display: inline-block;
    color: theme-color("eldro-white");
    font-family: "Roboto-Condensed-Regular", sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;

    .icon {
      display: block;
      width: 54px;
      height: 54px;
      margin: 10px auto 10px auto;
      background-repeat: no-repeat;

      &.icon-add-issue {
        background-image: url('/assets/images/icon-add-issue.png');
      }

      &.icon-issues {
        background-image: url('/assets/images/icon-issues.png');
      }

      &.icon-service {
        background-image: url('/assets/images/icon-service.png');
      }

      &.icon-reports {
        background-image: url('/assets/images/icon-reports.png');
      }

      &.icon-analysis {
        background-image: url('/assets/images/icon-analysis.png');
      }
    }
  }
}
