.btn {
  font-family: "Roboto-Condensed-Regular", sans-serif;
  letter-spacing: 2px;
  color: lighten(theme-color("eldro-white"), 5);
  padding: 5px 20px;
  text-transform: uppercase;
}

@each $color, $value in $custom-standard-colors {
  .btn-outline-#{$color} {
    border-color: opacify($value, 1);
    color: opacify($value, 1);
    text-transform: none;
  }
}
