.details {
  z-index: 1000;
  position: absolute;
  left: 325px;
  background-image: url('/assets/images/body-bg.png');
  padding: 5px;
  width: 300px;

  .details-list {
    max-height: 400px;
    // overflow-y: scroll;
    background-color: #c4c4c4;

    .details-list-item {
      display: flex;
      flex-direction: row;
      padding: 5px 10px;
      border-bottom: 1px solid #666;

      .name {
        flex-grow: 1;
        color: #000;
      }

      .status-bar {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-top: 2px;
        border: 1px solid #666;

        @each $color, $value in $status-colors {
          &.status-bar-#{$color} {
            background-color: transparentize($value, 0.2);
          }
        }
      }
    }
  }
}
