.table.table-bordered {
  border: none;

  thead {
    border: none;

    tr {
      border: none;
    }

    th {
      padding: 5px;
      color: $eldro-gold;
      text-align: center;
      border: 1px solid transparentize(theme-color("eldro-light-gray"), 0.5);
      border-left-color: transparentize(theme-color("eldro-gold"), 0.5);
      border-right-color: transparentize(theme-color("eldro-gold"), 0.5);
      border-top: none;

      &:first-child {
        padding-left: 0;
        border-left: none;
      }

      &:last-child {
        padding-right: 0;
        border-right: none;
      }
      
      &.empty-cell {
        position: relative;
        
        div {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
    }
  }

  &.report {
      tbody {
        tr.odd {
          background-color: rgba(#7b7b7b, 0.1);
        }
        td {
          padding: 0.30rem;
        }
      }
  }


  tbody {
    tr:first-of-type td {
      border-top: none;
    }

    tr td:first-child {
      border-left: none;
    }

    tr td:last-child {
      border-right: none;
    }

    td {
      border: 1px solid transparentize(theme-color("eldro-light-gray"), 0.5);
      border-left-color: transparentize(theme-color("eldro-gold"), 0.5);
      border-right-color: transparentize(theme-color("eldro-gold"), 0.5);

      &.title {
        color: theme-color("eldro-gold");
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 15px;
      }

      &.subtitle {
        color: theme-color("eldro-gold");
        letter-spacing: 1px;
        font-size: 15px;
      }

      &.ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

table.table-detail {
  border-top: 1px solid transparentize(theme-color("eldro-light-gray"), 0.5);
  margin-bottom: initial;
  tbody {
    td {
      border-right-color: transparentize(theme-color("eldro-gold"), 0.5);
      border-bottom-style: none;
      border-left: 4px double transparentize(theme-color("eldro-gold"), 0.5);
      border-top: none;
    }
    th {
      border: none;
    }
  }
}


.severity {
  display: block;
  margin: -12px 0 0 -12px;
  width: 10px;
  height: 10px;

  &.black {
    background-color: black;
  }
  &.red {
    background-color: red;
  }
  &.gray {
    background-color: gray;
  }
  &.violet {
    background-color: violet;
  }
  &.yellow {
    background-color: yellow;
  }
  &.green {
    background-color: green;
  }
}
