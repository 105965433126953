.modal-content {
  background: transparent url('/assets/images/body-bg.png');

  .modal-header {
    border-bottom: none;
    .modal-title {
      color: theme-color("eldro-gold");
    }
  }

  .modal-footer {
    border-top: none;
  }
}

.modal-xl .modal-dialog {
  max-width: 90%;
}
